import React, { useState } from 'react';
import useStyles from './styles';
import { footerMenus } from '../../utils/footerMenu';
import { doblekaholFooterMenu } from '../../utils/doblekaholFooterMenu';
import useWindowDimensions from '../../utils/windowDimention';
import { isMobile } from 'react-device-detect';
import { ids } from '../../constants';

const Footer = (props) => {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState(props.index);
  const { height, width } = useWindowDimensions();

  const styles = {
    mainDiv: {
      position: 'absolute',
      width: '100%',
      paddingLeft: '20%',
      paddingRight: '20%',
      top: height - (isMobile ? 90 : 110),
      paddingTop: 20,
      paddingBottom: 30,
      display: 'flex',
      justifyContent: 'space-between',
      zIndex: 1,
      background:  "linear-gradient(184deg, rgba(20,20,20,0.1) 0%, rgba(20,20,20,0.3) 0%, rgba(20,20,20,0.7) 100%)"
    },
  };

  const menu = Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypeDobleKahol ? doblekaholFooterMenu : footerMenus;

  return (
    <div style={styles.mainDiv}>
      {menu.map((item, index) => (
        <div
          key={`item${index}`}
          className={classes.footerMenuContainer}
          onClick={() => {
            setSelectedMenu(index)
            props.setIndex(index)
          }}
        >
          <img
            className={classes.menuIcon}
            src={props.index == index ? item.icon : item.iconWhite}
            alt={item.label}
            // style={{width: index === 1 ? 44: 30, alignItems: 'flex-end'}}
          />
          {/* <label className={classes.menuLabel} style={{fontSize: index === 1 ? 12: 10}}>{item.label}</label> */}
          <label className={classes.menuLabel} style={{color: props.index == index ? 'orange' : 'white'}}>{item.label}</label>
        </div>
      ))}
    </div>
  );
};

export default Footer;
