import { QuestionType } from './'

const question_easy: QuestionType[] = [
  {
    questionId: 10201,
    question: "A group of Lions is called ",
    correct_answer: "Pride",
    incorrect_answers: [
      "School",
      "Flock",
      "Pack"
    ]
  },
{
    questionId: 10202,
    question: "What is the 2nd generation of Elrond Lions Club called?",
    correct_answer: "Lioness",
    incorrect_answers: [
      "Mountain Lion",
      "Sea Lion",
      "Liger"
    ]
  },
{
    questionId: 10203,
    question: "Activity that can be done when you have a Lion and Lioness",
    correct_answer: "Breeding",
    incorrect_answers: [
      "Mating",
      "Playing",
      "Hunting"
    ]
  },
{
    questionId: 10204,
    question: "What can you earn passively by holding Lions and Lionesses?",
    correct_answer: "LKMEX Farming",
    incorrect_answers: [
      "LKNAS Farming",
      "LKMAK Farming",
      "LKMAX Farming"
    ]
  },
{
    questionId: 10205,
    question: "The 3rd Generation of Elrond Lions NFTs coming soon is",
    correct_answer: "Cubs",
    incorrect_answers: [
      "Babies",
      "Pets",
      "Eggs"
    ]
  },
{
    questionId: 10206,
    question: "________  has a 2nd generation of NFTs called Lioness",
    correct_answer: "Elrond Lions Club",
    incorrect_answers: [
      "Super Rare Bears",
      "Maka-bros",
      "Whaley "
    ]
  },
{
    questionId: 20201,
    question: "Whaley holders are entitled to what type of airdrops?",
    correct_answer: "Paid",
    incorrect_answers: [
      "Incentivized",
      "Partnered",
      "Sponsored"
    ]
  },
{
    questionId: 20202,
    question: "How many Whaleys did you need to get a baby whale?",
    correct_answer: "Two",
    incorrect_answers: [
      "One",
      "Three",
      "Four"
    ]
  },
{
    questionId: 20203,
    question: "The following is a benefit of owning a Whaley",
    correct_answer: "RAPT Token",
    incorrect_answers: [
      "WHAL Token",
      "WHLY Token",
      "RUPT Token"
    ]
  },
{
    questionId: 20204,
    question: "What is the total supply of Whaley?",
    correct_answer: "Four Thousand",
    incorrect_answers: [
      "Ten Thousand",
      "Six Thousand",
      "Seven Thousand"
    ]
  },
{
    questionId: 20205,
    question: "The founder and CEO of Whaley is",
    correct_answer: "Whaley Vale",
    incorrect_answers: [
      "Christian Whale",
      "Whale Bailey",
      "Amanda Whaley"
    ]
  },
{
    questionId: 20206,
    question: "_______  holders earn RAPT Tokens",
    correct_answer: "Whaley",
    incorrect_answers: [
      "Evoluzion",
      "MaiarPunks",
      "Mr. Ghost"
    ]
  },
{
    questionId: 30201,
    question: "Super Rare Bear that is transformed by a syringe is called",
    correct_answer: "Abomination",
    incorrect_answers: [
      "Obscenity",
      "Monstrosity",
      "Atrocity"
    ]
  },
{
    questionId: 30202,
    question: "What do you call Bears with a diamond at the bottom right?",
    correct_answer: "Ultra Rare Bears",
    incorrect_answers: [
      "Supreme Rare Bears",
      "Might Rare Bears",
      "Prime Rare Bears"
    ]
  },
{
    questionId: 30203,
    question: "The Super Rare Bears has a total supply of",
    correct_answer: "Ten Thousand",
    incorrect_answers: [
      "Eight Thousand",
      "Nine Thousand",
      "Seven Thousand"
    ]
  },
{
    questionId: 30204,
    question: "What do you call the animated syringe for Super Rare Bears?",
    correct_answer: "Abominator",
    incorrect_answers: [
      "Kryptonator",
      "Transformer",
      "Bear Syringe"
    ]
  },
{
    questionId: 30205,
    question: "The native token of the Super Rare Bears is",
    correct_answer: "RARE Token",
    incorrect_answers: [
      "BEAR Token",
      "SRB Token",
      "URB Token"
    ]
  },
{
    questionId: 30206,
    question: "____ turn into Abominations when injected with an Abominator",
    correct_answer: "Super Rare Bears",
    incorrect_answers: [
      "Maka-Bros",
      "Whaleys",
      "MaiarPunks"
    ]
  },
{
    questionId: 40201,
    question: "The art style used for MaiarPunks is called",
    correct_answer: "Pixel art",
    incorrect_answers: [
      "Abstract art",
      "Landscape art",
      "Doodle art"
    ]
  },
{
    questionId: 40202,
    question: "What are the first 1,000 Punks called?",
    correct_answer: "Gen one",
    incorrect_answers: [
      "Genesis",
      "The first",
      "Starters"
    ]
  },
{
    questionId: 40203,
    question: "The native token of MaiarPunks is called",
    correct_answer: "TOLKIEN Token",
    incorrect_answers: [
      "TOLKEN Token",
      "TOLKEIN Token",
      "TOLKIN Token"
    ]
  },
{
    questionId: 40204,
    question: "What is the city of MaiarPunks in GNOVERSE?",
    correct_answer: "MaiarPunks Capital City",
    incorrect_answers: [
      "The Otherside",
      "Voxel City",
      "Suplex City"
    ]
  },
{
    questionId: 40205,
    question: "The Dark Gods who corrupted mankind are",
    correct_answer: "The Chaldur",
    incorrect_answers: [
      "The Maldur",
      "The Andurin",
      "The Maiar"
    ]
  },
{
    questionId: 40206,
    question: "_______  uses pixel art for their NFTs",
    correct_answer: "MaiarPunks",
    incorrect_answers: [
      "Evoluzion",
      "Elva Forest",
      "Whaley"
    ]
  },
{
    questionId: 50201,
    question: "The kind of business that Elva Forest is in is called",
    correct_answer: "Online to Offline",
    incorrect_answers: [
      "Brand to brand",
      "Brand to Consumer",
      "Finance"
    ]
  },
{
    questionId: 50202,
    question: "What kind of story does Elva Forest narrate?",
    correct_answer: "Fantasy Saga",
    incorrect_answers: [
      "Alternate World",
      "Dark Age",
      "Science Fiction"
    ]
  },
{
    questionId: 50203,
    question: "Items that Elva Forest will distribute worldwide are called",
    correct_answer: "Elva Forest Merchandise",
    incorrect_answers: [
      "Elva Forest Token",
      "Elva Forest Services",
      "Elva Forest Freebies"
    ]
  },
{
    questionId: 50204,
    question: "What does Elva Forest aim to bring to the Elrond Ecosystem?",
    correct_answer: "Aesthetic Creations",
    incorrect_answers: [
      "Generational Art",
      "Fantastical Music",
      "Top tier Merchandise"
    ]
  },
{
    questionId: 50205,
    question: "The Elva Forest collection that was recently released is",
    correct_answer: "Odyssey Collection",
    incorrect_answers: [
      "Saga Collection",
      "Fantastic Collection",
      "Forest Collection"
    ]
  },
{
    questionId: 50206,
    question: "The people of Elva Forest are",
    correct_answer: "Elves",
    incorrect_answers: [
      "Orcs",
      "Ghosts",
      "Lions"
    ]
  },
{
    questionId: 60201,
    question: "What do you call the 3 kinds of Evoluzionists?",
    correct_answer: "Primates Humans Cyborgs",
    incorrect_answers: [
      "Gorillas Humans Robots",
      "Humans Cyborgs Aliens",
      "Primates Cyborgs Aliens"
    ]
  },
{
    questionId: 60202,
    question: "The 2 backgrounds for Legendary Evoluzion NFTs are called",
    correct_answer: "Candy Pumpkin",
    incorrect_answers: [
      "Ocean Avocado",
      "Obsidian Diamond",
      "Liliac Cloudy"
    ]
  },
{
    questionId: 60203,
    question: "What do you call the individuals of Evoluzion NFTs?",
    correct_answer: "Evoluzionists",
    incorrect_answers: [
      "Evolutionists",
      "Evonists",
      "Evoluzionites"
    ]
  },
{
    questionId: 60204,
    question: "You can see each unique trait of the Evoluzionists in the",
    correct_answer: "Genes Laboratory",
    incorrect_answers: [
      "Genes Lair",
      "Genes Mixer",
      "Genes Container"
    ]
  },
{
    questionId: 60205,
    question: "What do you call the 2 backgrounds for Rare Evoluzionists?",
    correct_answer: "Diamond Ketchup",
    incorrect_answers: [
      "Avocado Cloudy",
      "Candy Obsidian",
      "Pumpkin Ocean"
    ]
  },
{
    questionId: 60206,
    question: "Evoluzion NFT ______ are shown in Genes Laboratory",
    correct_answer: "Traits",
    incorrect_answers: [
      "Numbers",
      "Projects",
      "Weaknesses"
    ]
  },
{
    questionId: 70201,
    question: "Name of the upcoming app from Gokai Labs is called",
    correct_answer: "Elven app",
    incorrect_answers: [
      "Gokai app",
      "Mr. Ghost app",
      "Gokai Ghost app"
    ]
  },
{
    questionId: 70202,
    question: "Governance token of Gokai Labs is called",
    correct_answer: "Mr Ghost NFT",
    incorrect_answers: [
      "$GHOST",
      "Gokai NFT",
      "$GOK"
    ]
  },
{
    questionId: 70203,
    question: "You can earn passively in the Elven app through",
    correct_answer: "Staking",
    incorrect_answers: [
      "Minting",
      "Holding",
      "Gaming"
    ]
  },
{
    questionId: 70204,
    question: "Who is the creator of the Maiar Ghosts NFTs?",
    correct_answer: "Gokai Labs",
    incorrect_answers: [
      "Ghost Labs",
      "Gokai Inc",
      "Ghost Inc"
    ]
  },
{
    questionId: 70205,
    question: "What app by Gokai Labs has an upgradeable smart contract?",
    correct_answer: "Elven Tools Dapp",
    incorrect_answers: [
      "Elven Utility App",
      "Elven Items Dapp",
      "Elven Smart App"
    ]
  },
{
    questionId: 70206,
    question: "Mr Ghost NFT token is the ________ of Gokai Labs",
    correct_answer: "Governance token",
    incorrect_answers: [
      "Effort token",
      "Government token",
      "Gokai token"
    ]
  },
{
    questionId: 80201,
    question: "Games that OrcPunks will make for the physical world is",
    correct_answer: "Board Games",
    incorrect_answers: [
      "Video Games",
      "Hand Games",
      "Word Games"
    ]
  },
{
    questionId: 80202,
    question: "What type of art is used for OrcPunks design?",
    correct_answer: "Pixel",
    incorrect_answers: [
      "Abstract",
      "Landscape",
      "Portait"
    ]
  },
{
    questionId: 80203,
    question: "Native token of the Orc is called",
    correct_answer: "ZORG",
    incorrect_answers: [
      "ZOC",
      "ZOG",
      "ZOR"
    ]
  },
{
    questionId: 80204,
    question: "What is the activity to earn more ZORG coins?",
    correct_answer: "Staking",
    incorrect_answers: [
      "Minting",
      "Gaming",
      "Holding"
    ]
  },
{
    questionId: 80205,
    question: "OrcPunks metaverse is called",
    correct_answer: "Orcverse",
    incorrect_answers: [
      "Orcworld",
      "Orcdom",
      "Orctopia"
    ]
  },
{
    questionId: 80206,
    question: "OrcPunks use ZORG as their ________ token",
    correct_answer: "Native",
    incorrect_answers: [
      "Governance",
      "Community",
      "Modern"
    ]
  },
{
    questionId: 90201,
    question: "Who is the male Maka-Bros character?",
    correct_answer: "Vlad",
    incorrect_answers: [
      "Vikan",
      "Vladimir",
      "Viktor"
    ]
  },
{
    questionId: 90202,
    question: "The airdrop for people who have the 5 Maka-Bros characters",
    correct_answer: "Maka Objects",
    incorrect_answers: [
      "Maka Furniture",
      "Maka Sis",
      "Maka Stuff"
    ]
  },
{
    questionId: 90203,
    question: "Where was the Maka-Bros brand created?",
    correct_answer: "Italy",
    incorrect_answers: [
      "Rome",
      "Transylvania",
      "Russia"
    ]
  },
{
    questionId: 90204,
    question: "The amount of copies the Maka-Bros chapter has is",
    correct_answer: "One Thousand",
    incorrect_answers: [
      "Two Thousand",
      "Three Thousand",
      "Four Thousand"
    ]
  },
{
    questionId: 90205,
    question: "Who is the 5th Maka-Bros character in the series?",
    correct_answer: "Bat",
    incorrect_answers: [
      "Igor",
      "Wanda",
      "Buddy"
    ]
  },
{
    questionId: 90206,
    question: "_______  is the female Maka-Bros character",
    correct_answer: "Wanda",
    incorrect_answers: [
      "Buddy",
      "Bat",
      "Vlad"
    ]
  },
{
    questionId: 100201,
    question: "Who is the doctor who created the Elrond Mutant Eheadz?",
    correct_answer: "Dr. Gaban",
    incorrect_answers: [
      "Dr. Mutant",
      "Dr. Ehead",
      "Dr. Eme"
    ]
  },
{
    questionId: 100202,
    question: "EME stands for",
    correct_answer: "Elrond Mutilum Element",
    incorrect_answers: [
      "Elrond Mutant Element",
      "Elrond Micro Evolve",
      "Elrond Mutilum Evolve"
    ]
  },
{
    questionId: 100203,
    question: "Eheadz underwent the process of",
    correct_answer: "Mutation",
    incorrect_answers: [
      "Metamorphosis",
      "Evolution",
      "Degredation"
    ]
  },
{
    questionId: 100204,
    question: "What Dr. Gaban wants for the Eheadz is",
    correct_answer: "Perfection",
    incorrect_answers: [
      "Evolution",
      "Extinction",
      "Empowerment"
    ]
  },
{
    questionId: 100205,
    question: "Who will benefit from being an EME HODLER?",
    correct_answer: "EHEADZ Believers",
    incorrect_answers: [
      "EHEADZ Mutants",
      "Dr. Gaban",
      "EHEADZ flippers"
    ]
  },
{
    questionId: 100206,
    question: "Doctor Gaban used EME to mutate the ____",
    correct_answer: "Eheadz",
    incorrect_answers: [
      "Punks",
      "Orcs",
      "Ghosts"
    ]
  },
{
    questionId: 210201,
    question: "Enabling many ___ opportunities is Effort Economy's goal",
    correct_answer: "Earning",
    incorrect_answers: [
      "Staking",
      "Minting",
      "Gaming"
    ]
  },
{
    questionId: 210202,
    question: "Effort Economy aims for ___ ___ adoption",
    correct_answer: "Internet scale",
    incorrect_answers: [
      "Wide scale",
      "Web3 scale",
      "Massive scale"
    ]
  },
{
    questionId: 210203,
    question: "Effort Economy's learn to earn app is called",
    correct_answer: "Rondle",
    incorrect_answers: [
      "Fun Bets",
      "Bengga",
      "T6"
    ]
  },
{
    questionId: 210204,
    question: "Effort Economy wants players to experience different __ apps",
    correct_answer: "Effort to Earn",
    incorrect_answers: [
      "Play to Earn",
      "Learn to Earn",
      "Compete to Earn"
    ]
  },
{
    questionId: 210205,
    question: "The token of Effort Economy is called",
    correct_answer: "EFFORT Token",
    incorrect_answers: [
      "EFFORT Coin",
      "EFFORT Currency",
      "EFFORT Money"
    ]
  },
{
    questionId: 310201,
    question: "The Elrond Network token is called",
    correct_answer: "EGLD",
    incorrect_answers: [
      "ESDT",
      "ELRD",
      "GOLD"
    ]
  },
{
    questionId: 310202,
    question: "What is the race of Elrond's namesake in Lord of the Rings?",
    correct_answer: "Elf",
    incorrect_answers: [
      "Dwarf",
      "Human",
      "Hobbit"
    ]
  },
{
    questionId: 310203,
    question: "What is the first decentralized exchange in Elrond?",
    correct_answer: "Maiar Exchange",
    incorrect_answers: [
      "Elrond Exchange",
      "Maiar Trade",
      "Elrond Trade"
    ]
  },
{
    questionId: 310204,
    question: "A Co-founder and CEO of the Elrond Network is",
    correct_answer: "Beniamin Cincu",
    incorrect_answers: [
      "Lucian Todea",
      "Lucian Mincu",
      "Felix Crisan"
    ]
  },
{
    questionId: 310205,
    question: "The Elrond Network is an",
    correct_answer: "Internet Scale Blockchain",
    incorrect_answers: [
      "Web3 Scale Blockchain",
      "Wide Scale Blockchain",
      "Massive Scale Blockchain"
    ]
  },
{
    questionId: 410201,
    question: "A new type of scam involving cryptocurrency is called a",
    correct_answer: "Rug Pull",
    incorrect_answers: [
      "Robbery",
      "Fraud",
      "Swindle"
    ]
  },
{
    questionId: 410202,
    question: "NFT stands for ",
    correct_answer: "Non fungible token",
    incorrect_answers: [
      "Non Funky Token",
      "Non Fungible Toy",
      "Non Funny Tabloid"
    ]
  },
{
    questionId: 410203,
    question: "P2P stands for",
    correct_answer: "Peer to peer",
    incorrect_answers: [
      "Pear to pear",
      "Pass to pass",
      "Price to price"
    ]
  },
{
    questionId: 410204,
    question: "What key allows one to receive or send cryptocurrency?",
    correct_answer: "Wallet Address",
    incorrect_answers: [
      "Home address",
      "Key address",
      "Binary address"
    ]
  },
{
    questionId: 410205,
    question: "The market where prices are expected to rise is called",
    correct_answer: "Bull Market",
    incorrect_answers: [
      "Bear Market",
      "Beaver Market",
      "Brand Market"
    ]
  },
{
    questionId: 410206,
    question: "The market where prices are expected to decline is called",
    correct_answer: "Bear Market",
    incorrect_answers: [
      "Bull Market",
      "Bat Market",
      "Baboon Market"
    ]
  },
{
    questionId: 410207,
    question: "The production of cryptocurrency is called",
    correct_answer: "Mining",
    incorrect_answers: [
      "Minting",
      "Crypting",
      "Coining"
    ]
  },
{
    questionId: 410208,
    question: "Tokens that are not Bitcoin are called",
    correct_answer: "Alt coins",
    incorrect_answers: [
      "Alt token",
      "Side coin",
      "Side token"
    ]
  },
{
    questionId: 410209,
    question: "ICO stands for",
    correct_answer: "Initial coin offering",
    incorrect_answers: [
      "Initial Coin Order",
      "Interim Coin Organizer",
      "Internal Coin Offering"
    ]
  },
{
    questionId: 410210,
    question: "The creation of an NFT is called",
    correct_answer: "Minting",
    incorrect_answers: [
      "Creation",
      "Drawing",
      "Buying"
    ]
  },
{
    questionId: 410211,
    question: "What do you call groups of data within a blockchain?",
    correct_answer: "Blocks",
    incorrect_answers: [
      "Cubes",
      "Spheres",
      "Bricks"
    ]
  },
{
    questionId: 410212,
    question: "The instant messaging platform that projects use is",
    correct_answer: "Discord",
    incorrect_answers: [
      "Messenger",
      "Whatsapp",
      "Snapchat"
    ]
  },
{
    questionId: 410213,
    question: "The term used to describe excitement for a project is",
    correct_answer: "Hyped",
    incorrect_answers: [
      "Thrilled",
      "Exhilarated",
      "Stirred"
    ]
  },
{
    questionId: 410214,
    question: "A crowdsourced pool of cryptocurrencies is called",
    correct_answer: "Liquidity Pool",
    incorrect_answers: [
      "Liquid Pool",
      "Lucidity Pool",
      "Longetvity Pool"
    ]
  },
{
    questionId: 410215,
    question: "A document showing the aims and strategies of a project is",
    correct_answer: "White Paper",
    incorrect_answers: [
      "Project Document",
      "Goal Paper",
      "Brand Document"
    ]
  },
{
    questionId: 410216,
    question: "Expression of belief an asset is gonna rise greatly in price",
    correct_answer: "To the moon",
    incorrect_answers: [
      "To the stars",
      "To the heavens",
      "To the sun"
    ]
  },
{
    questionId: 410217,
    question: "Holding an investment when there is pressure to sell is",
    correct_answer: "Diamond Hands",
    incorrect_answers: [
      "Gold Hands",
      "Steel Hands",
      "Ruby Hands"
    ]
  },
{
    questionId: 410218,
    question: "Where are cryptocurrency holdings stored?",
    correct_answer: "Wallet",
    incorrect_answers: [
      "Bag",
      "Purse",
      "Luggage"
    ]
  },
{
    questionId: 410219,
    question: "What social media platform projects use to build up hype?",
    correct_answer: "Twitter",
    incorrect_answers: [
      "Instagram",
      "Facebook",
      "Tiktok"
    ]
  },
{
    questionId: 410220,
    question: "The concept where people can play games to earn is",
    correct_answer: "Play to Earn",
    incorrect_answers: [
      "Game to Earn",
      "Play to Win",
      "Game to Win"
    ]
  },
{
    questionId: 410221,
    question: "Distributed database shared among nodes of a comp network is",
    correct_answer: "Blockchain",
    incorrect_answers: [
      "Blockchip",
      "Chainblock",
      "Datachain"
    ]
  },
{
    questionId: 410222,
    question: "Validates and finalizes transactions on the Elrond Network",
    correct_answer: "Layer One",
    incorrect_answers: [
      "Layer Two",
      "Layer Three",
      "Layer Four"
    ]
  },
{
    questionId: 410223,
    question: "Method of making a database more manageable for computers is",
    correct_answer: "Sharding",
    incorrect_answers: [
      "Shading",
      "Sharking",
      "Sharping"
    ]
  },
{
    questionId: 410224,
    question: "Consensus mechanism used to verify new crypto transactions",
    correct_answer: "Proof of Stake",
    incorrect_answers: [
      "Proof of Steak",
      "Proof of Strafe",
      "Proof of Streak"
    ]
  },
{
    questionId: 410225,
    question: "POW stands for",
    correct_answer: "Proof of Work",
    incorrect_answers: [
      "Proof of Warn",
      "Proof of Word",
      "Proof of Win"
    ]
  },
{
    questionId: 410226,
    question: "Programs on a blockchain that run when conditions are met is",
    correct_answer: "Smart contract",
    incorrect_answers: [
      "Smart program",
      "Smart app",
      "Smart checker"
    ]
  },
{
    questionId: 410227,
    question: "A secure method of cryptocurrency completely offline is",
    correct_answer: "Cold Wallet",
    incorrect_answers: [
      "Freeze Wallet",
      "Ice Wallet",
      "Frost Wallet"
    ]
  },
{
    questionId: 410228,
    question: "Basic rules that allow data to be shared between computers are",
    correct_answer: "Protocols",
    incorrect_answers: [
      "Regulations",
      "Laws",
      "Doctrine"
    ]
  },
{
    questionId: 410229,
    question: "Term used for the cheapest price of NFT in a collection is",
    correct_answer: "Floor price",
    incorrect_answers: [
      "Price ceiling",
      "Low price",
      "Dip price"
    ]
  },
{
    questionId: 410230,
    question: "Digital marketplace where you buy and sell cryptocurrency is",
    correct_answer: "Exchange",
    incorrect_answers: [
      "Market",
      "Tradestop",
      "Barter"
    ]
  },
{
    questionId: 410231,
    question: " The total number of tokens in a collection or a project is",
    correct_answer: "Total Supply",
    incorrect_answers: [
      "Complete supply",
      "Total inventory",
      "Stock"
    ]
  },
{
    questionId: 410232,
    question: "Software-based cryptocurrency wallet connected to Internet",
    correct_answer: "Hot Wallet",
    incorrect_answers: [
      "Fire Wallet",
      "Heat Wallet",
      "Flame Wallet"
    ]
  },
{
    questionId: 410233,
    question: "Sending tokens for free to eligible addresses is called",
    correct_answer: "Airdrop",
    incorrect_answers: [
      "Shipment",
      "Air pass",
      "Mail drop"
    ]
  },
{
    questionId: 410234,
    question: "A computer that connects to a blockchain network is called",
    correct_answer: "Node",
    incorrect_answers: [
      "Chain vein",
      "Web glue",
      "Pc node"
    ]
  },
{
    questionId: 410235,
    question: "What do people who often make risky and bad bets called?",
    correct_answer: "Degens",
    incorrect_answers: [
      "Apes",
      "Whales",
      "Bears"
    ]
  },
{
    questionId: 410236,
    question: "Fee to pay to blockchain network for transactions is called",
    correct_answer: "Gas",
    incorrect_answers: [
      "Toll",
      "Tax",
      "Interest"
    ]
  },
{
    questionId: 410237,
    question: "What does PFP stand for?",
    correct_answer: "Profile Picture",
    incorrect_answers: [
      "Profile Partition",
      "Profile Port",
      "Profile Pass"
    ]
  },
{
    questionId: 410238,
    question: "What cryptocurrency is pegged to a fiat currency?",
    correct_answer: "Stable coin",
    incorrect_answers: [
      "Volatile token",
      "Fiat coin",
      "Base Token"
    ]
  },
{
    questionId: 410239,
    question: "Words used to access your cryptocurrency wallet are called",
    correct_answer: "Seed Phrase",
    incorrect_answers: [
      "Word collection",
      "Phrase list",
      "Sentence list"
    ]
  },
{
    questionId: 410240,
    question: "Amount of tokens distributed outside the creator contract is",
    correct_answer: "Circulating supply",
    incorrect_answers: [
      "Distributed supply",
      "Revolving supply",
      "Rotating supply"
    ]
  }
];

export default question_easy;