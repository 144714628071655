import "./style.css";
import { maxGuesses, seed, urlParam } from "../Words/util";
import { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

import Leaders from '../../assets/images/footer-images/leaders.png';
// import Tips from '../../assets/images/footer-images/tips.png';
import Prizes from '../../assets/images/footer-images/prizes.png';
import ImgConfetti from '../../assets/images/common-images/confetti.png';

import EntryFeeCover from "./EntryFeeCover";
import { useNavigate } from "react-router-dom";
import { ids, routes } from "../../constants";
import { getFeatured, getUserProfile, updateClass, getNotificationCount, checkUserVersion, getJoinSummary, getNotificationsConfetti, getLessons } from "../../services/ApiService";
import { effortHelper } from "../../helpers/effortHelper";
import { nftHelper } from "../../helpers/nftHelper";
import MoreFooter from "./MoreFooter";
import useStyles from "./styles";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import Sidebar from "../../Components/Sidebar";
import Welcome from '../../assets/images/common-images/welcome_gift.png';
import MaxJoinWord from '../../assets/images/common-images/max_joins_word.png';
import MaxJoinTopic from '../../assets/images/common-images/max_joins_topic.png';
import MaxJoinNumber from '../../assets/images/common-images/max_joins_number.png';
import Confetti from '../../assets/images/gifs/confetti.gif';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function App() {
  const [showEntryCover, setShowEntryCover] = useState(false)
  const [footerIndex, setFooterIndex] = useState(0)
  const [userData, setUserData] = useState({ avatarSmall: "", classId: 1, difficultyClassId: 4 });
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [words, setWords] = useState();
  const [topics, setTopics] = useState();
  const [numbers, setNumbers] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const [isNewUser, setIsNewUser] = useState(false);
  const [joinSummary, setJoinSummary] = useState([]);
  const [showMaxJoin, setShowMaxJoin] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showConfettiData, setShowConfettiData] = useState(false);
  const [notifConfettiData, setNotifConfettiData] = useState("");
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [lessonData, setLessonData] = useState();

  const footerIdx = Number(localStorage.getItem("footerIndex"));
  const itemIdx = Number(localStorage.getItem("itemIndex"));

  const navigate = useNavigate();
  const classes = useStyles();

  // const engagementData={
  //   newId: 4242,
  //   title: 'Word',
  //   preloaderImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649235005787.png',
  //   joinFee: 100
  // }

  const fetchUserData = async () => {
    try {
      setLoader(true);
      const response = await getUserProfile();
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setUserData(response.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const logUser = async() => {
    try {
      setLoader(true);
      const response = await checkUserVersion({ platform: isIOS ? 'ios' : isAndroid ? 'android' : !isMobile ? 'desktop' : 'others', version: parseInt(process.env.REACT_APP_VERSION.replaceAll('.','')) });
      if (response) {
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(`login=${e}`)
    }
  }

  const fetchGetWin = async () => {
    try {
      setLoader(true);
      const response = await getJoinSummary();
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setJoinSummary(response.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const fetchLessonData = async () => {
    try {
      const response = await getLessons();
      if (response) {
        const data = response.data.data;
        const head = data.filter(item => item.sortOrder == 1);
        const tail = data.filter(item => item.sortOrder !== 1);
        setLessonData([
          ...head,
          ...tail
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchNotifCount = async () => {
    try {
      setLoader(true);
      const response = await getNotificationCount();
      if (response) {
        setNotifCount(response.data.count);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const fetchNotifConfetti = async () => {
    try {
      setLoader(true);
      const response = await getNotificationsConfetti();
      if (response && response.data) {
        let item = response.data;
        let format = item.text_format
        let values = JSON.parse(JSON.stringify(JSON.parse(item.text_values)));
        for (let key in values) {
          var value = values[key]
          format = format.replace(`%${key}%`,value.trim())
        }
        setNotifConfettiData(format);
        setShowConfetti(true);
        setTimeout(() => { 
          setShowConfetti(false) 
          setShowConfettiData(true);
          setTimeout(() => {
            setShowConfettiData(false);
          }, 3000)
        }, 2000);
        setLoader(false);
      }
      else {
        setNotifConfettiData("");
        setShowConfetti(false);
        setShowConfettiData(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const computeClass = async () => {
    console.log(`compute class`)
    const address = localStorage.getItem("maiarAddress");
    const creators = [ //check in maiar page
      "erd1qf9et8d4swd09unnv0rdzezpkyxg5f2tk00tlr039nhjyz8xrm5qfj53ww", //elva
      "erd1jpr49da65vae0wjl73e3ghv443swxqaqvacu2f6uelvcnuxxw5jqktqsyh", //elrond lion
      "erd1qqqqqqqqqqqqqpgqeys5lt360l342m4dk2edehykktwn34djys5s46rk5f", //evoluzion
      "erd14edl4fvr8wc2sczrz5af6tfmfucgqzsh4u73hnxw96l07cekdg6schwtsh", //maiarpunks
      "erd1qqqqqqqqqqqqqpgqf6qde2f9dt8u943la9ehgvemzldx7rccxjhs3nqucu", //superrarebears
      "erd18e7t3fquqjm9rs6385tqu0pk543e43e44rlkrhtdzfd64rgu7jrq6jrza9", //whaley
      "erd1qqqqqqqqqqqqqpgqy2elehk25f6kp3zvf4wtd96sax6z78gqejeqq4l6n7", //Elrond Mutant Eheadz 
      "erd1qqqqqqqqqqqqqpgqy2jfag2jfhre8aem7kvz3ncrcq6ta52zys5s27cge2", //orcpunks
      "erd1qqqqqqqqqqqqqpgqfewmp7u7ea2p6pmu9sgv8hn8d3kgtje4ys5sjen2lu", //maka-bros
      "erd1qqqqqqqqqqqqqpgqw763s7ea3jksp6gr98kmj6psehtu3flndswsswu7hk", //mr ghost
    ];
    
    let classId: number, difficultyClassId: number;
    const EFFORT_CHECK = 1000;
    const effort = await effortHelper({
      user_wallet: address,
    });
    let hasNft = false;
    creators.every(async (creator) => {
      const data = {
        user_wallet: address,
        creator_wallet: creator,
      };
      const nft = await nftHelper(data);
      // console.log(`nft=${JSON.stringify(nft)}`)
      if (nft.length > 0) {
        hasNft = true;
        if (hasNft && effort >= EFFORT_CHECK) {
          //class: a, diff: low
          classId = ids.classA;
          difficultyClassId = 1;
        } else if (hasNft && effort < EFFORT_CHECK) {
          //class: b, diff: mid
          classId = ids.classB;
          difficultyClassId = 2;
        }
        const userClass = {
          winEnggClassId: classId,
          difficultyClassId: difficultyClassId,
        };
    
        if (userData && classId > userData.classId) {
          await updateClass(userClass);
        }
      }
      // console.log(`hasNft=${hasNft}; effort=${effort}`)
    });
    // console.log(`hasNft=${hasNft}; effort=${effort}`)
    if (hasNft && effort >= EFFORT_CHECK) {
      //class: a, diff: low
      classId = ids.classA;
      difficultyClassId = 1;
    } else if (hasNft && effort < EFFORT_CHECK) {
      //class: b, diff: mid
      classId = ids.classB;
      difficultyClassId = 2;
    } else if (!hasNft && effort >= EFFORT_CHECK) {
      //class: c, diff: high
      classId = ids.classC;
      difficultyClassId = 3;
    } else {
      //class: d, diff: high
      classId = ids.classD;
      difficultyClassId = 4;
    }
    // console.log(`classId=${classId}; difficultyClassId=${difficultyClassId}`)
    const userClass = {
      winEnggClassId: classId,
      difficultyClassId: difficultyClassId,
    };

    if (userData && classId > userData.classId) {
      await updateClass(userClass);
    }
  }

  useEffect(() => {
    fetchGetSked();
    fetchNotifCount();
    fetchNotifConfetti();
    fetchGetWin();
    fetchLessonData();
    logUser();
    setFooterIndex(footerIdx);
    footerChanged(footerIdx);
    if (Number(localStorage.getItem("isNewUser")) === 1) {
      setIsNewUser(true);
    }
  }, [])

  const fetchGetSked = async () => {
    try {
      setLoader(true);
      const response = await getFeatured();

      if (response) {
        const words = response.data.filter((item) => {
          return item.funTypeFamily.id == ids.FunTypeFamilyRondleWords;
        });
        if (words.length > 0) {
          loadWords(words[0].items);
        }
        const topics = response.data.filter((item) => {
          return item.funTypeFamily.id == ids.FunTypeFamilyRondleTopics;
        });
        if (topics.length > 0) {
          loadTopics(topics[0].items);
        }
        const numbers = response.data.filter((item) => {
          return item.funTypeFamily.id == ids.FunTypeFamilyRondleNumbers;
        });
        if (numbers.length > 0) {
          loadNumbers(numbers[0].items);
        }
        setLoader(false);
      }
      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const loadWords = (data) => {
    let arr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      // for paid tourney use /////////////
      newItem.funTypeFamilyId = ids.FunTypeFamilyRondleWords;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      newItem.joinType = item.joinType;
      newItem.gameType = item.gameType;
      newItem.isSpecial = item.isSpecial;
      newItem.targetScore = item.targetScore;
      newItem.topPlayers = item.topPlayers;
      newItem.joinFee = item.joinFee;
      newItem.joinTicket = item.joinTicket;
      newItem.joinHour = item.joinHour;
      newItem.joinFeeType = item.joinFeeType;
      newItem.potMoney = item.potMoney;
      newItem.potMoneyAsOf = item.potMoneyAsOf;
      newItem.userCap = item.userCap;
      newItem.engagementType = item.engagementType;
      newItem.tutorialUrl = item.tutorialUrl;
      newItem.joinRequirement = item.joinRequirement;
      /////////////////////////////////////
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    //Arranging the cache url for tourney videos
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      let dict = {
        ...arr[i],
        gameTitle: arr[i].title,
        backgroundImage: arr[i].imageBig,
        play: false,
      };
      newArr = newArr.concat([dict]);
    }

    setWords(newArr);
    if (footerIdx == 1) {
        // console.log(`newArr=${JSON.stringify(newArr)}; footerIdx=${footerIdx}`)
        setData(newArr);
        setSelectedSlide(itemIdx);
     }
  };

  const loadTopics = (data) => {
    let arr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      // for paid tourney use /////////////
      newItem.funTypeFamilyId = ids.FunTypeFamilyRondleTopics;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      newItem.joinType = item.joinType;
      newItem.gameType = item.gameType;
      newItem.isSpecial = item.isSpecial;
      newItem.targetScore = item.targetScore;
      newItem.topPlayers = item.topPlayers;
      newItem.joinFee = item.joinFee;
      newItem.joinTicket = item.joinTicket;
      newItem.joinHour = item.joinHour;
      newItem.joinFeeType = item.joinFeeType;
      newItem.potMoney = item.potMoney;
      newItem.potMoneyAsOf = item.potMoneyAsOf;
      newItem.userCap = item.userCap;
      newItem.engagementType = item.engagementType;
      newItem.tutorialUrl = item.tutorialUrl;
      newItem.joinRequirement = item.joinRequirement;
      /////////////////////////////////////
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    //Arranging the cache url for tourney videos
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      let dict = {
        ...arr[i],
        gameTitle: arr[i].title,
        backgroundImage: arr[i].imageBig,
        play: false,
      };
      newArr = newArr.concat([dict]);
    }

    setTopics(newArr);
    if (footerIdx == 0) {
        // console.log(`newArr=${JSON.stringify(newArr)}; footerIdx=${footerIdx}`)
        setData(newArr);
        setSelectedSlide(itemIdx);
     }
  };

  const loadNumbers = (data) => {
    let arr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      // for paid tourney use /////////////
      newItem.funTypeFamilyId = ids.FunTypeFamilyRondleNumbers;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      newItem.joinType = item.joinType;
      newItem.gameType = item.gameType;
      newItem.isSpecial = item.isSpecial;
      newItem.targetScore = item.targetScore;
      newItem.topPlayers = item.topPlayers;
      newItem.joinFee = item.joinFee;
      newItem.joinTicket = item.joinTicket;
      newItem.joinHour = item.joinHour;
      newItem.joinFeeType = item.joinFeeType;
      newItem.potMoney = item.potMoney;
      newItem.potMoneyAsOf = item.potMoneyAsOf;
      newItem.userCap = item.userCap;
      newItem.engagementType = item.engagementType;
      newItem.tutorialUrl = item.tutorialUrl;
      newItem.joinRequirement = item.joinRequirement;
      /////////////////////////////////////
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    //Arranging the cache url for tourney videos
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      let dict = {
        ...arr[i],
        gameTitle: arr[i].title,
        backgroundImage: arr[i].imageBig,
        play: false,
      };
      newArr = newArr.concat([dict]);
    }

    setNumbers(newArr);
    if (footerIdx == 2) {
        // console.log(`newArr=${JSON.stringify(newArr)}; footerIdx=${footerIdx}`)
        setData(newArr);
        setSelectedSlide(itemIdx);
     }
  };

  const footerChanged = (index: number) => {
    setFooterIndex(index);
    setShowEntryCover(false);
    setData([]);
    setFooterIndex(index);
    localStorage.setItem("footerIndex", index);
    // console.log(`footerChanged.footerindex=${localStorage.getItem("footerIndex")}`);
    setSelectedSlide(0);
    if (footerIdx != index) {
      localStorage.setItem("itemIndex", 0);
    }

    switch (index) {
      case 0:
        setData(topics);
        break;
      case 2:
        setData(numbers);
        break;
      default:
        setData(words);
        break;
    }
    // console.log(`index=${index}; data=${JSON.stringify(data)}`)

    fetchUserData();
    computeClass();
  }

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    navigate(routes.login);
  };

  const notificationHandler = () => {
    setLoader(false);
    navigate(routes.notification, { state: {lessonData: lessonData} });
  };

  const historyHandler = () => {
    setLoader(false);
    navigate(routes.history, {state: { userData: userData} });
  };

  const collectionHandler = () => {
    setLoader(false);
    navigate(routes.collection, {state: { userData: userData} });
  };

  const lessonHandler = () => {
    setLoader(false);
    navigate(routes.lesson, {state: { userData: userData, lessonData: lessonData} });
  }

  const sidebarIndexClicked = (index) => {
    switch (index) {
      case 0:
        notificationHandler();
        break;
      case 3:
        collectionHandler();
        break;
      case 4:
        historyHandler();
        break;
      case 6:
        logoutHandler();
        break;
      case 7:
        lessonHandler();
        break;
      default:
    }
  };

  const _onShowTips = (title: string, image: string) => {
    navigate(routes.show, {
      state: {
        title: title,
        image: image,
      },
    })
  }

  const gameTypeToRoute = (gameType: number) => {
    switch (gameType) {
      case ids.gameTypeWordle:
        return routes.word;
      case ids.gameTypeFillInTheBlanks:
        return routes.topic;
      case ids.gameType2048:
        return routes.number;
      case ids.gameTypeMultipleChoice:
        return routes.topic2;
      case ids.gameTypePinoyHenyo:
        return routes.henyo;
      case ids.gameTypePinoyHenyo2:
        // return routes.henyo2;  
        return routes.henyo;
      case ids.gameTypePinoyHenyo3:
        return routes.henyo3;  
      case ids.gameTypeLotto:
        return routes.lotto;
      case ids.gameTypeSlotMachine:
        return routes.slot;
    }
  }

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  const _onShowLeaderboard = (engagementData) => {
    // if (engagementData.funTypeFamilyId === ids.FunTypeFamilyRondleTopics) {
    //   navigate(routes.famers, { state: { engagementData: engagementData } });
    // }
    // else {
      navigate(routes.leaders, { state: { engagementData: engagementData } });
    // }
  }

  const profileClicked = () => {
    setSidebarOpen(false);
    navigate(routes.profile);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Number(localStorage.getItem("autoJoin")) == 1) {
        onJoin(selectedSlide ? Number(itemIdx) : selectedSlide);
        localStorage.setItem("autoJoin", "0");
      }
    }, 1000);
    return () => clearTimeout(timer);
  })
  
  const onJoin = (index: number) => {
    var item;
    if (footerIdx == 0 && topics.length > index) {
      item = topics[index];
    } else if (footerIdx == 1 && words.length > index) {
      item = words[index];
    } else if (footerIdx == 2 && numbers.length > index) {
      item = numbers[index];
    }
    // window.alert(`item.title=${item.title}`)
    if (new Date(item.joinEndDate) > new Date()) {
      if (item.funTypeFamilyId === ids.FunTypeFamilyRondleTopics) {
        const js = joinSummary.filter((js) => js.engagementId === item.newId);
        if (js.length > 0) {
          if (js[0].wins >= ids.MaxWinsTopic) {
            setShowMaxJoin(true);
          } else {
            navigate(gameTypeToRoute(item.gameType), {
              state: {
                engagementData: data[index],
                userData: userData,
                joinSummary: joinSummary.filter((js) => js.engagementId === item.newId),
                lessonData: lessonData,
                gameType: item.gameType
              }
            });
          }
        }
        else {
          navigate(gameTypeToRoute(item.gameType), {
            state: {
              engagementData: data[index],
              userData: userData,
              joinSummary: joinSummary.filter((js) => js.engagementId === item.newId),
              lessonData: lessonData,
              gameType: item.gameType
            }
          });
        }
      }
      else if (item.funTypeFamilyId === ids.FunTypeFamilyRondleWords) {
        const data = joinSummary.filter((js) => js.engagementId === item.newId);
        if (data.length > 0) {
          if (data[0].wins >= ids.MaxWinsWord) {
            setShowMaxJoin(true);
          } else {
            setShowEntryCover(true)
          }
        }
        else {
          setShowEntryCover(true)
        }
      }
      else if (item.funTypeFamilyId === ids.FunTypeFamilyRondleNumbers) {
        const data = joinSummary.filter((js) => js.engagementId === item.newId);
        if (data.length > 0) {
          if (data[0].wins >= ids.MaxWinsNumber) {
            setShowMaxJoin(true);
          } else {
            setShowEntryCover(true)
          }
        }
        else {
          setShowEntryCover(true)
        }
      }
    } else {
      window.location.reload(false);
    }
  }

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2
  };

  return (
    <div className={"App-container"} style={{display: 'flex'}}>
      <Header
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={true}
        avatar={userData.avatarSmall}
        notificationCount={notifCount}
      />
      {sidebarOpen && (
          <Sidebar
            isSoundMuted={false}
            notificationCount={notifCount}
            avatar={userData.avatarSmall}
            avatarClicked={profileClicked}
            setSidebarOpen={setSidebarOpen}
            sidebarIndexClicked={sidebarIndexClicked}
          />
        )}
      {/* { footerIndex == 0 && */}
      {
        <div style={{width: '100%', height: '100vh', alignItems: "center"}} >
          <Carousel
            preventMovementUntilSwipeScrollTolerance
            transitionTime={100}
            emulateTouch={true}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            axis={"vertical"}
            showIndicators={false}
            dynamicHeight={true}
            selectedItem={
              Number(itemIdx) != selectedSlide ? Number(itemIdx) : selectedSlide
            }
            onChange={(item) => {
              setSelectedSlide(item);
              localStorage.setItem("itemIndex", item);
            }}
          >
            {data &&
              data.map((item, index) => (
                <div
                  style={{ display: "block", width: '100vw', height: '90vh' }}
                  key={item.newId}
                >
                  {selectedSlide != index && (
                    <img
                      className={classes.homeContent}
                      src={item.imageBig}
                    />
                  )}
                  {selectedSlide == index && (
                    <div>
                      <img src={data[index].imageBig} style={{width: '100%', position: 'absolute', left: 0, top: 0, 
                        minHeight: isMobile ? '90vh':  '100vh',
                        objectFit: 'cover',
                        maxHeight: isMobile ? '90vh':  '100vh'}}/> 
                      <video
                        onClick={() => onJoin(index)}
                        src={data[index].video}
                        playsInline
                        autoPlay
                        loop
                        muted
                        preload="auto"
                        style={{width: '100%', position: 'absolute', left: 0, top: 0, 
                        minHeight: isMobile ? '90vh':  '100vh',
                        objectFit: 'cover',
                        maxHeight: isMobile ? '90vh':  '100vh'}}
                      />
                      <div className={classes.hostContainer}>
                        <div
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p className={classes.hostedBy}>Hosted by:</p>
                          <div className={classes.hostImgContainer}>
                            <div className={classes.hostImgContainer2}>
                              <img
                                className={classes.hostImg}
                                src={data[index].source.avatarBig}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {new Date(data[index].joinEndDate) > new Date() && (
                        <MoreFooter
                          leftIcon={Prizes}
                          rightIcon={Leaders}
                          onLeftIconPress={() => _onShowTips(data[index].title, data[index].tutorialUrl)}
                          onRightIconPress={() => _onShowLeaderboard(data[index])}
                          // onTutorial={() => _onTutorial(true, item)}
                          // onMore={() => _onMore(!showMoreCover)}
                        />
                      )}
                    </div>
                  )}
              </div>
            ))}
          </Carousel>
          <div className={classes.sliderIndicatorContainer}>
            {data && data.length > 1 &&
              data.map((slide, index) => (
                <div
                  key={`item${index}`}
                  style={{
                    width: selectedSlide == index ? 10 : 7,
                    height: selectedSlide == index ? 10 : 7,
                    background: selectedSlide == index ? "#CD49B8" : "transparent",
                  }}
                  className={classes.sliderIndicator}
                  onClick={() => setSelectedSlide(index)}
                />
              ))}
          </div>
        </div>  
      }
      {/* { footerIndex == 1 && 
        <img src={Topics} style={{width: '100%', height: '70%', objectFit: 'cover'}}/>
      }
      { footerIndex == 2 && 
        <img src={Numbers} style={{width: '100%', height: '70%', objectFit: 'cover'}}/>
      } */}
      {showEntryCover &&
        <EntryFeeCover 
          onCancel={() => setShowEntryCover(false)}
          onTips={() => _onShowTips(data[selectedSlide].title, data[selectedSlide].tipsImageBig) }
          engagementData={data[selectedSlide]}
          onJoin={() => {
            navigate(gameTypeToRoute(data[selectedSlide].gameType), {
              state: {
                engagementData: data[selectedSlide],
                userData: userData,
                joinSummary: joinSummary.filter((js) => js.engagementId === data[selectedSlide].newId),
                lessonData: lessonData,
                gameType: data[selectedSlide].gameType
              },
            });
          }}
        />
      }
      {/* {isNewUser && (
          <div
            className={classes.tutorialContainer} */}
          <div className={`bg_modal_background ${isNewUser && 'modal_bg_show'}`}></div>
          <div className={`bg_modal ${isNewUser && 'modal_show'}`}
            onClick={() => {
              setIsNewUser(false);
              localStorage.setItem("isNewUser", "0");
            }}
          >
            <img src={Welcome} className={classes.tutorialImage} />
          </div>
      {/* )} */}
      {/* {showMaxJoin && ( 
          // <div
      //   className={classes.tutorialContainer} */}
          <div className={`bg_modal_background ${showMaxJoin && 'modal_bg_show'}`}></div>
          <div className={`bg_modal ${showMaxJoin && 'modal_show'}`}
            onClick={() => setShowMaxJoin(false)}
          >
            <img src={footerIndex === 0 ? MaxJoinTopic : footerIndex === 1 ? MaxJoinWord : MaxJoinNumber } className={classes.tutorialImage} />
          </div>
      {/* )} */}
      {showConfetti && (
        <div
          className={classes.tutorialContainer}
        >
          <img src={Confetti} className={classes.tutorialContainer} />
        </div>
      )}
      {showConfettiData && (
        <div
          className={classes.confettiTextContainer}
        >
          <img src={ImgConfetti} className={classes.confettiContainer} />
          <p className={classes.confettiText}>{notifConfettiData}</p>
        </div>
      )}
      <Footer setIndex={(index: number) => footerChanged(index)} index={footerIndex} />
    </div>
  );
}

export default App;
