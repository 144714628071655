import React from "react";
import useStyles from "./styles";
import useWindowDimensions from '../../utils/windowDimention';
import { isIOS, isMobile } from 'react-device-detect';

const MoreFooter = (props) => {
  const { height, width } = useWindowDimensions();

  const styles = {
    mainDiv: {
      position: 'absolute',
      width: "100%",
      height: 50,
      zIndex: 11,
      top: isMobile ? (height - 125) : 'calc(95vh - 100px)'
    },
  };

  const classes = useStyles();
  
  return (
    <div style={styles.mainDiv}>
      <div className={classes.morefooter}>
        <div onClick={props.onLeftIconPress}>
          <img src={props.leftIcon} className={classes.leftfootericn} />
        </div>
        <div onClick={props.onRightIconPress}>
          <img src={props.rightIcon} className={classes.rightfootericn} />
        </div>
      </div>
    </div>
  );
};

export default MoreFooter;
